import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

const Hero = ({ contentModuleId }) => {

    const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutHero {
                edges {
                    node {
                        id
                        heading
                        subheading
                        description {
                            description
                        }
                        ctaText
                        ctaUrl
                        image {
                            fluid(quality: 100) {
                                ...GatsbyContentfulFluid
                            }
                        }
                    }
                }
            }
        }
    `);

    const content = data.allContentfulLayoutHero.edges.find(edge => edge.node.id === contentModuleId);

    return (
        <section className="hero container section mx-auto">
            <div className="flex items-center w-full text-center md:w-1/2 md:text-left">
                <div className="hero__tagline-content-wrap">
                    <h2 className="font-bold text-5xl" data-sal="fade">{content.node.heading}</h2>
                    <p className="text-2xl" data-sal="fade" data-sal-delay="100">{content.node.subheading}</p>
                    <p className="w-full mt-2 md:w-3/4" data-sal="fade" data-sal-delay="200">{content.node.description.description}</p>
                    <a href={content.node.ctaUrl}><button className="btn btn--primary mt-8" data-sal="fade" data-sal-delay="300">{content.node.ctaText}</button></a>
                </div>
            </div>
            <div className="w-full pt-16 md:w-1/2 md:pt-0">
                <img
                    src={content.node.image.fluid.src}
                    className="mx-auto w-full"
                    stype={{ 'max-height': '342px', 'max-width': '476px' }}
                    alt="Hero"
                    data-sal="slide-right"
                    data-sal-delay="400"
                    data-sal-duration="500"
                />
            </div>
        </section>
    );
};

Hero.propTypes = {
    contentModuleId: PropTypes.string.isRequired
}

export default Hero;