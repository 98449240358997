import React from "react"
import Layout from "../components/layout"
// import SEO from '../components/seo';
import { graphql } from "gatsby"

import Sections from "sections"

export default function HomePage({ data }) {
  const title = data.contentfulLayout.title
  const description = data.contentfulLayout.description
  const menus = data.contentfulLayout.menu
  const contentModule = data.contentfulLayout.contentModule
  // console.log(contentModule)

  return (
    <Layout menus={menus}>
      {/* <SEO title={ title } description={ description } /> */}
      {contentModule &&
        contentModule.length > 0 &&
        contentModule.map(content => (
          <Sections
            contentModuleId={content.id}
            type={content.__typename}
            key={content.id}
          />
        ))}
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulLayout(slug: { eq: "/" }) {
      id
      slug
      title
      description
      contentful_id
      menu {
        name
        type
        menuItems {
          id
          title
          url
        }
      }
      contentModule {
        ... on Node {
          __typename
          id
        }
      }
    }
  }
`
